import USBLink from '@usb-shield/react-link'
import styles from '@/components/ProductsArrayWrapper/productsArrayWrapper.module.scss'
import React from 'react'
import { USBColumn } from '@usb-shield/react-grid'
import { defaultColLayoutOptions } from '@/utils/usb-grid.util'

let cardColumn = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 4,
    large: 4,
    medium: 4,
    small: 4,
  },
  justify: 'start',
  align: 'start',
}
const FeatureCard = ({tiles} : any) => {  
  return tiles?.map((value: any, index: any) => {
    const key = index
    // returning if product name is empty to avoid empty product cards
    if (!value.title) return
    return (
      <USBColumn
        layoutOpts={cardColumn}
        className={styles.uSBCardCol + ' ' + styles.heightLayout}
        key={key}
      >
        <React.Fragment key={key}>
          <div className={styles.block}>
              <h3 className={styles.headH3 + ' ' + styles.topHeadLayout}>
                {value.title}
              </h3>
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{ __html: value.shortDescription }}
            />
              <div className={styles.linkLayout + ' ' + styles.topLayout}>
                <USBLink
                  linkType="basic"
                  href={value.ctaLink}
                  addClasses={styles.solutionAreaLink + ' ' + styles.marginLayout}
                  ariaProps={{ label: value.ariaLabel }}
                >
                  {value.ctaText}
                </USBLink>
              </div>        
          </div>
        </React.Fragment>
      </USBColumn>
    )
  })
}

export default FeatureCard
